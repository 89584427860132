'use client';
import { clsx } from 'clsx';
import type { StackProps } from 'geist/components';
import { Stack, Avatar } from 'geist/components';
import {
  Accessibility,
  LayoutShift,
  EyeDashed,
  InboxUnread,
  MenuAlt,
  Message,
  Share,
  ToggleOff,
} from 'geist/new-icons/16';
import type {
  ForwardRefComponent,
  HTMLMotionProps,
  MotionProps,
} from 'framer-motion';
import * as React from 'react';
import type { JSX } from 'react';
import styles from './toolbar.module.css';

interface ToolbarContext {
  hoveredFeature: Feature | null;
  setHoveredFeature: React.Dispatch<React.SetStateAction<Feature | null>>;
}

export const ToolbarContext = React.createContext({} as ToolbarContext);

export const Features = {
  Comments: { delay: getDelayByIndex(0) },
  'Feature Flags': { delay: getDelayByIndex(1) },
  'CMS Drafts': { delay: getDelayByIndex(2) },
  Collaborators: { delay: getDelayByIndex(3) },
  Accessibility: { delay: getDelayByIndex(4) },
  'Layout Shift': { delay: getDelayByIndex(5) },
  Share: { delay: getDelayByIndex(6) },
};

export type Feature = keyof typeof Features;

/////////////////////////////////////////////////////////////////////////////////////////

export function Toolbar({
  collapsed,
  animate = false,
  ...props
}: {
  animate?: boolean;
  collapsed?: boolean;
  as?: ForwardRefComponent<HTMLDivElement, HTMLMotionProps<'div'>>;
  onMouseLeave?: () => void;
} & MotionProps): JSX.Element {
  const context = React.useContext(ToolbarContext);

  function getActionProps(id: Feature): React.HTMLAttributes<HTMLElement> & {
    'data-fade'?: boolean;
    'data-highlight'?: boolean;
    delay?: number;
  } {
    if (animate) {
      return {
        delay: Features[id].delay,
        'aria-label': id,
        'data-fade': context.hoveredFeature
          ? context.hoveredFeature !== id
          : false,
        'data-highlight': context.hoveredFeature === id,
        onMouseEnter: () => {
          context.setHoveredFeature(id);
        },
      };
    }

    return {
      'aria-label': id,
    };
  }

  return (
    <Stack
      align="center"
      className={styles.root}
      data-animate={animate}
      direction="row"
      justify="center"
    >
      <Stack
        align="center"
        aria-label="This element represents the full suite of the Vercel Toolbar, including various actions and meta-data."
        className={clsx(styles.toolbar, 'dark-theme')}
        direction="row"
        justify="space-between"
        {...(props as StackProps)}
      >
        <Stack
          direction="row"
          {...getActionProps('Comments')}
          style={{
            animationDelay: `${Features.Comments.delay}ms`,
          }}
        >
          <Stack className={styles.fade} direction="row">
            <Action aria-label="Comment">
              <Message />
            </Action>
            <Action aria-label="Inbox">
              <InboxUnread />
            </Action>
          </Stack>
        </Stack>
        <hr />
        <Action {...getActionProps('Feature Flags')}>
          <ToggleOff />
        </Action>
        <Action {...getActionProps('CMS Drafts')}>
          <EyeDashed />
        </Action>
        <hr />
        <Stack
          {...getActionProps('Collaborators')}
          align="center"
          className={styles.collaborators}
          direction="row"
          style={{
            animationDelay: `${Features.Collaborators.delay}ms`,
          }}
        >
          <Stack direction="row">
            <User color="var(--ds-amber-800)" username="JohnPhamous" />
            <User color="var(--ds-red-800)" username="megbird" />
            <User color="var(--ds-teal-900)" username="rauno" />
          </Stack>
        </Stack>
        <hr />
        {!collapsed && (
          <>
            <Action {...getActionProps('Accessibility')}>
              <Accessibility />
            </Action>
            <Action {...getActionProps('Layout Shift')}>
              <LayoutShift />
            </Action>
          </>
        )}
        <Action {...getActionProps('Share')} aria-label="Share">
          <Share />
        </Action>
        {collapsed ? (
          <Action>
            <MenuAlt />
          </Action>
        ) : null}
      </Stack>
    </Stack>
  );
}

/////////////////////////////////////////////////////////////////////////////////////////

function Action({
  children,
  delay,
  ...props
}: {
  children: JSX.Element;
  delay?: number;
}): JSX.Element {
  return (
    <Stack
      align="center"
      as="li"
      className={styles.action}
      justify="center"
      style={
        {
          'animation-delay': delay ? `${delay}ms` : undefined,
        } as React.CSSProperties
      }
      {...props}
    >
      <Stack align="center" aria-hidden justify="center">
        {children}
      </Stack>
    </Stack>
  );
}

/////////////////////////////////////////////////////////////////////////////////////////

function User({
  username,
  color,
}: {
  username: string;
  color: `var(--ds-${string})`;
}): JSX.Element {
  return (
    <Avatar
      className={styles.user}
      size={37}
      style={
        {
          '--color': color,
        } as React.CSSProperties
      }
      username={username}
    />
  );
}

/////////////////////////////////////////////////////////////////////////////////////////

function getDelayByIndex(index: number): number {
  return 150 * index;
}

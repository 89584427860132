'use client';
import { VercelMark } from 'geist/logos';
import { ButtonLink } from '@pyra/multi-zone/link';
import { Intent } from '@vercel/site-analytics/vercel-client';
import type { JSX } from 'react';
import styles from './hero.module.css';

export function Buttons(): JSX.Element {
  return (
    <>
      <ButtonLink
        className={styles.primaryButton}
        data-track-intent={Intent.GetStarted}
        href="/new"
        key="deploy"
        prefix={<VercelMark color="var(--ds-background-200)" height={14} />}
        shape="rounded"
        size={{
          sm: 'medium',
          md: 'medium',
          lg: 'large',
        }}
      >
        <span aria-hidden data-size="small">
          Deploy
        </span>
        <span data-size="large">Start Deploying</span>
      </ButtonLink>
      <ButtonLink
        className={styles.secondaryButton}
        data-track-intent={Intent.ContactSales}
        href="/contact/sales"
        key="tour"
        shadow
        shape="rounded"
        size={{
          sm: 'medium',
          md: 'medium',
          lg: 'large',
        }}
        type="secondary"
      >
        Get a Demo
      </ButtonLink>
    </>
  );
}

'use client';

import { Grid, Stack, Text } from 'geist/components';
import type { MotionValue } from 'framer-motion';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import * as React from 'react';
import { useMemo, type JSX } from 'react';
import type { Feature } from '@pyra/marketing-shared/visuals/toolbar';
import {
  Features,
  Toolbar,
  ToolbarContext,
} from '@pyra/marketing-shared/visuals/toolbar';
import styles from './hero.module.css';
import { Buttons } from './buttons';

export function Hero(): JSX.Element {
  return (
    <>
      <Grid.Cross column={1} row={1} />
      {/*******************************/}
      <main className={styles.root}>
        <Grid
          columns={{ sm: 8, md: 12 }}
          height="preserve-aspect-ratio"
          rows={{ sm: 5, md: 5, lg: 4 }}
        >
          <ToolbarHero />
        </Grid>
        <Grid columns={1} rows={1}>
          <Grid.Cell
            className={styles.cell}
            column="1"
            data-variant="ctas"
            overflow
            row="1"
            solid
          >
            <Text
              as="h1"
              className={styles.title}
              variant={{
                sm: 'heading-24',
                md: 'heading-32',
                lg: 'heading-40',
              }}
              weight={600}
            >
              A deployment for every idea.
            </Text>
            <Text
              as="p"
              className={styles.subtitle}
              color="gray-900"
              variant={{
                sm: 'copy-14',
                md: 'copy-18',
                lg: 'copy-20',
              }}
            >
              Traditional deployment tools weren’t built for frontend
              development. Get the <strong>DX Platform</strong> that has teams
              shipping faster with higher quality.
            </Text>
            <Stack direction="row" gap={{ sm: 3, md: 4 }}>
              <Buttons />
            </Stack>
          </Grid.Cell>
          <Grid.Cross
            column={-1}
            row={-1}
            style={{
              transform: 'translateY(1px)',
            }}
          />
        </Grid>
      </main>
    </>
  );
}

function ToolbarHero(): JSX.Element {
  const [hoveredFeature, setHoveredFeature] = React.useState<Feature | null>(
    null,
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const blur = useMotionValue(0);

  const style = {
    filter: useTransform(blur, (value) => `blur(${value}px)`),
  };

  const context = useMemo(
    () => ({ hoveredFeature, setHoveredFeature }),
    [hoveredFeature],
  );

  return (
    <Stack align="center" className={styles.hero} justify="center" ref={ref}>
      <ToolbarContext.Provider value={context}>
        <Badges side="top" style={style}>
          <Badge color="blue" delay={Features.Comments.delay}>
            Comments
          </Badge>
          <Badge color="purple" delay={Features['CMS Drafts'].delay}>
            CMS Drafts
          </Badge>
          <Badge color="green" delay={Features.Accessibility.delay}>
            Accessibility
          </Badge>
          <Badge color="pink" delay={Features.Share.delay}>
            Share
          </Badge>
        </Badges>
        <Toolbar
          animate
          as={motion.div}
          drag
          dragConstraints={ref}
          dragElastic={0.1}
          dragSnapToOrigin
          onDrag={(_, info) => {
            const offset = Math.abs(info.offset.x) + Math.abs(info.offset.y);
            const blurValue = Math.min(5, Math.max(0, offset / 10));
            blur.set(blurValue);
          }}
          onDragEnd={() => {
            document.body.classList.remove('geist-grabbing');
            void animate(blur, 0, {
              duration: 0.5,
            });
          }}
          onDragStart={() => {
            document.body.classList.add('geist-grabbing');
          }}
          onMouseLeave={() => {
            setHoveredFeature(null);
          }}
          whileDrag={{
            cursor: 'grabbing',
          }}
          whileHover={{
            cursor: 'grab',
          }}
        />
        <Badges side="bottom" style={style}>
          <Badge color="teal" delay={Features['Feature Flags'].delay}>
            Feature Flags
          </Badge>
          <Badge color="amber" delay={Features.Collaborators.delay}>
            Collaborators
          </Badge>
          <Badge color="red" delay={Features['Layout Shift'].delay}>
            Layout Shift
          </Badge>
        </Badges>
      </ToolbarContext.Provider>
    </Stack>
  );
}

function Badge({
  children,
  color,
  delay,
  style,
  ...props
}: {
  children: string;
  color: string;
  delay: number;
  style?: React.CSSProperties;
}): JSX.Element {
  const context = React.useContext(ToolbarContext);
  return (
    <Text
      as="span"
      className={styles.badge}
      data-color={color}
      data-fade={
        context.hoveredFeature ? context.hoveredFeature !== children : null
      }
      variant="label-16"
      weight={500}
      {...props}
      style={{
        ...style,
        animationDelay: `${delay}ms`,
      }}
    >
      {children}
    </Text>
  );
}

function Badges({
  children,
  side,
  ...props
}: {
  children: React.ReactElement[];
  side: 'top' | 'bottom';
  style: {
    filter: MotionValue<string>;
  };
}): JSX.Element {
  return (
    // @ts-expect-error - Polymorphism is hard
    <Stack
      as={motion.div}
      className={styles.badges}
      data-side={side}
      direction="row"
      {...props}
    >
      {React.Children.map(children, (child, index) => {
        let angle = 0;
        let y = 0;

        if (side === 'top') {
          if (index === 0) {
            angle = -4;
          }
          if (index === 1) {
            angle = -1;
            y = -14;
          }
          if (index === 2) {
            angle = 1;
            y = -14;
          }
          if (index === 3) {
            angle = 4;
          }
        }

        if (side === 'bottom') {
          if (index === 0) {
            angle = 2;
            y = 7;
          }

          if (index === 1) {
            angle = 0;
            y = 14;
          }
          if (index === 2) {
            angle = -2;
            y = 7;
          }
        }

        return React.cloneElement(child, {
          style: {
            transform: `rotate(${angle}deg) translateY(${y}px)`,
          },
        });
      })}
    </Stack>
  );
}
